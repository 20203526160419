
import AppDatabase from '../../services/db/app-db.service';
import { CoreoPendingRecordState, CoreoRecord, CoreoRecordCustomFilter, CoreoRecordFilter, CoreoRecordSyncStatus } from '../../types';
import { TypeKeys } from '../actions';
import { ApplicationState } from '../reducers';
import { getAppDefaultState, getAuthUser, getAuthUserId } from '../selectors';

/******************************************************************************
 * ACTIONS
 *****************************************************************************/

export interface RecordsLoadRecordAction {
  type: TypeKeys.RECORDS_LOAD_RECORD;
  id: number;
}

export interface RecordsLoadRecordSuccessAction {
  type: TypeKeys.RECORDS_LOAD_RECORD_SUCCESS;
  record: CoreoRecord;
}

export interface RecordsLoadRecordFailureAction {
  type: TypeKeys.RECORDS_LOAD_RECORD_FAILURE;
  error: any;
}

export interface RecordsUpdateFilterAction {
  type: TypeKeys.RECORDS_UPDATE_FILTER;
  filter: CoreoRecordFilter;
}

export interface RecordsClearFilterAction {
  type: TypeKeys.RECORDS_CLEAR_FILTER;
}

export interface RecordsAddCustomFilterAction {
  type: TypeKeys.RECORDS_ADD_CUSTOM_FILTER;
}
export interface RecordsUpdateCustomFilterAction {
  type: TypeKeys.RECORDS_UPDATE_CUSTOM_FILTER;
  customFilter: CoreoRecordCustomFilter;
}
export interface RecordsDeleteCustomFilterAction {
  type: TypeKeys.RECORDS_DELETE_CUSTOM_FILTER;
  id: string;
}

export interface RecordsClearAction {
  type: TypeKeys.RECORDS_CLEAR;
}

export interface RecordsLoadMoreAction {
  type: TypeKeys.RECORDS_LOAD_MORE;
}

export interface RecordsLoadMoreSuccessAction {
  type: TypeKeys.RECORDS_LOAD_MORE_SUCCESS;
  records: CoreoRecord[];
}

export interface RecordsLoadFailureAction {
  type: TypeKeys.RECORDS_LOAD_MORE_FAILURE;
  error?: any;
}

export interface RecordsLoadMapAction {
  type: TypeKeys.RECORDS_LOAD_MAP;
}

export interface RecordsLoadMapSuccessAction {
  type: TypeKeys.RECORDS_LOAD_MAP_SUCCESS;
  mapId: string;
  recordsCount: number;
}

export interface RecordsLoadMapFailureAction {
  type: TypeKeys.RECORDS_LOAD_MAP_FAILURE;
  error: any;
}

export interface RecordsSavePendingAction {
  type: TypeKeys.RECORDS_SAVE_PENDING;
  id: number;
  count: number;
}

export interface RecordsSubmitAction {
  type: TypeKeys.RECORDS_SUBMIT;
  id: number;
}

export interface RecordsSubmitSuccessAction {
  type: TypeKeys.RECORDS_SUBMIT_SUCCESS;
  id: number;
}

export interface RecordsSubmitFailureAction {
  type: TypeKeys.RECORDS_SUBMIT_FAILURE;
  id: number;
  pendingState: CoreoPendingRecordState;
}

export interface RecordsDiscardPendingAction {
  type: TypeKeys.RECORDS_DISCARD_PENDING;
  id: number;
}

export type RecordsActions =
  | RecordsLoadRecordAction
  | RecordsLoadRecordSuccessAction
  | RecordsLoadRecordFailureAction
  | RecordsUpdateFilterAction
  | RecordsClearFilterAction
  | RecordsAddCustomFilterAction
  | RecordsUpdateCustomFilterAction
  | RecordsDeleteCustomFilterAction
  | RecordsClearAction
  | RecordsLoadMoreAction
  | RecordsLoadMoreSuccessAction
  | RecordsLoadFailureAction
  | RecordsLoadMapAction
  | RecordsLoadMapSuccessAction
  | RecordsLoadMapFailureAction
  | RecordsSavePendingAction
  | RecordsSubmitAction
  | RecordsSubmitSuccessAction
  | RecordsSubmitFailureAction
  | RecordsDiscardPendingAction
  ;

/******************************************************************************
 * ACTION CREATORS
 *****************************************************************************/
export const recordsUpdateFilter = (filter: CoreoRecordFilter): RecordsUpdateFilterAction => ({
  type: TypeKeys.RECORDS_UPDATE_FILTER,
  filter
});

export const recordsClearFilter = (): RecordsClearFilterAction => ({
  type: TypeKeys.RECORDS_CLEAR_FILTER
});

export const recordsAddCustomFilter = (): RecordsAddCustomFilterAction => ({
  type: TypeKeys.RECORDS_ADD_CUSTOM_FILTER
});

export const recordsUpdateCustomFilter = (customFilter: CoreoRecordCustomFilter): RecordsUpdateCustomFilterAction => ({
  type: TypeKeys.RECORDS_UPDATE_CUSTOM_FILTER,
  customFilter
});

export const recordsDeleteCustomFilter = (id: string): RecordsDeleteCustomFilterAction => ({
  type: TypeKeys.RECORDS_DELETE_CUSTOM_FILTER,
  id
});

export const recordsClear = (): RecordsClearAction => ({
  type: TypeKeys.RECORDS_CLEAR
});

export const recordsLoadRecord = (id: number): RecordsLoadRecordAction => ({
  type: TypeKeys.RECORDS_LOAD_RECORD,
  id
});

export const recordsLoadRecordSuccess = (record: CoreoRecord): RecordsLoadRecordSuccessAction => ({
  type: TypeKeys.RECORDS_LOAD_RECORD_SUCCESS,
  record
});

export const recordsLoadRecordFailure = (error?): RecordsLoadRecordFailureAction => ({
  type: TypeKeys.RECORDS_LOAD_RECORD_FAILURE,
  error
})

export const recordsLoadMore = (): RecordsLoadMoreAction => ({
  type: TypeKeys.RECORDS_LOAD_MORE
});

export const recordsLoadMoreSuccess = (records: CoreoRecord[]): RecordsLoadMoreSuccessAction => ({
  type: TypeKeys.RECORDS_LOAD_MORE_SUCCESS,
  records
});

export const recordsLoadMoreFailure = (error?): RecordsLoadFailureAction => ({
  type: TypeKeys.RECORDS_LOAD_MORE_FAILURE,
  error
})

export const recordsLoadMap = (): RecordsLoadMapAction => ({
  type: TypeKeys.RECORDS_LOAD_MAP
})

export const recordsLoadMapSuccess = (mapId: string, recordsCount: number): RecordsLoadMapSuccessAction => ({
  type: TypeKeys.RECORDS_LOAD_MAP_SUCCESS,
  mapId,
  recordsCount
});

export const recordsLoadMapFailure = (error?): RecordsLoadMapFailureAction => ({
  type: TypeKeys.RECORDS_LOAD_MAP_FAILURE,
  error
});

export const recordsDiscardPending = (id: number): RecordsDiscardPendingAction => ({
  type: TypeKeys.RECORDS_DISCARD_PENDING,
  id
});

/******************************************************************************
 * THUNKS
 *****************************************************************************/

export const recordSave = (record: CoreoRecord) => async (_dispatch, getState: () => ApplicationState): Promise<number> => {

  const state = getState();
  const userId = getAuthUserId(state);
  const defaultState = getAppDefaultState(state);

  const now = new Date()
  const nowStr = now.toISOString();

  const recordToSubmit: CoreoRecord = {
    ...record,
    userId,
    state: defaultState.stateId,
    syncState: CoreoRecordSyncStatus.PENDING_UPDATE,
    createdAt: nowStr,
    updatedAt: nowStr,
    deletedAt: null
  };

  await AppDatabase.instance.saveRecord(recordToSubmit);
  return recordToSubmit.id;
};


// Mark the record as deleted - set deletedAt to now and set the SyncState to PENDING_DELETE
// Find all child records and mark them as deleted as well
export const recordDelete = (record: CoreoRecord) => async (): Promise<void> => {
  if (AppDatabase.isNewRecord(record)) {
    return AppDatabase.instance.deletePendingRecord(record);
  }
  return AppDatabase.instance.prepareRecordForDelete(record);
}

export const recordRestore = (record: CoreoRecord) => async (): Promise<void> => {
  await AppDatabase.instance.restoreRecord(record);
}

export const buildRecordClone = (toClone: CoreoRecord, date: string, userId: number, state: number): CoreoRecord => {
  const clone = (record: CoreoRecord): CoreoRecord => {
    const result: CoreoRecord = {
      ...record,
      id: null,
      syncState: CoreoRecordSyncStatus.PENDING_UPDATE,
      createdAt: date,
      updatedAt: date,
      userId,
      deletedAt: null,
      state: state,
      associates: {},
      attachments: record.attachments.map(a => ({
        ...a,
        id: null,
        size: 0
      }))
    };

    for (const key in record.associates) {
      result.associates[key] = [];
      for (const a of record.associates[key]) {
        result.associates[key].push(clone(a));
      }
    }
    return result;
  }
  return clone(toClone);
}

export const recordClone = (record: CoreoRecord) => async (_dispatch, getState: () => ApplicationState): Promise<void> => {
  const state = getState();
  const now = new Date()
  const nowStr = now.toISOString();
  const defaultState = getAppDefaultState(state);
  const user = getAuthUser(state);

  const toClone = buildRecordClone(record, nowStr, user.userId, defaultState.stateId);
  return AppDatabase.instance.saveRecord(toClone);
}
