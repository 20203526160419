import { createSelector } from 'reselect';

import { FormState, FormsState } from './forms.reducer';
import { CoreoAssociateMap } from '../../types';

export const getFormsForms = (state: FormsState): FormState[] => state.forms;
export const getFormsCurrentForm = (state: FormsState): FormState => state.forms[state.forms.length - 1];
export const getFormsCurrentFormId = createSelector(getFormsCurrentForm, form => form?.id);
export const getFormsForm = uuid => (state: FormsState): FormState => state.forms.find(f => f.uuid === uuid);
export const getFormsFormSections = uuid => createSelector(getFormsForm(uuid), form => form.sections);
export const getFormsFormBlocks = uuid => createSelector(getFormsFormSections(uuid), sections => sections.reduce((acc, section) => acc.concat(section.blocks), []));
export const getFormsFormRecord = uuid => createSelector(getFormsForm(uuid), form => form?.record ?? null);
export const getFormsFormDirty = uuid => createSelector(getFormsForm(uuid), form => form?.dirty);
export const getFormsFormReady = uuid => createSelector(getFormsForm(uuid), form => form?.ready);
export const getFormsFormSectionId = uuid => createSelector(getFormsForm(uuid), form => form?.sectionId);
export const getFormsFormNextSectionId = uuid => createSelector(getFormsForm(uuid), form => form?.nextSectionId);
export const getFormsFormPrevSectionId = uuid => createSelector(getFormsForm(uuid), form => form?.prevSectionId);

export const getFormsCurrentFormHasAnyGeometry = createSelector(getFormsCurrentForm, ({ record }) => {
  if (!record) {
    return false;
  }
  if (record.geometry) {
    return true;
  }

  // Helper function to recursively check for geometry in associates
  const hasGeometryInAssociates = (associates: CoreoAssociateMap): boolean => {
    if (!associates || Object.keys(associates).length === 0) {
      return false;
    }

    return Object.values(associates).some(records =>
      records.some(record =>
        record.geometry || hasGeometryInAssociates(record.associates)
      )
    );
  };

  return hasGeometryInAssociates(record.associates);
});